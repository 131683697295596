import React from 'react';

import SuperTokens, {SuperTokensWrapper} from "supertokens-auth-react";
import Session from "supertokens-auth-react/recipe/session";
import {canHandleRoute, getRoutingComponent} from "supertokens-auth-react/ui";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import {EmailPasswordPreBuiltUI} from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import EmailVerification from "supertokens-auth-react/recipe/emailverification";
import { EmailVerificationPreBuiltUI } from "supertokens-auth-react/recipe/emailverification/prebuiltui";

SuperTokens.init({
  appInfo: {
    appName: "Roomies",
    apiDomain: "https://dev.hestialiv.com/auth",
    websiteDomain: "https://dev.hestialiv.com",
    apiBasePath: "/auth",
    websiteBasePath: "/",
  },
  recipeList: [
    EmailVerification.init({
      mode: "REQUIRED", // or "OPTIONAL"
    }),
    EmailPassword.init({
      signInAndUpFeature: {
        signUpForm: {
          formFields: [{
            id: "firstName",
            label: "First Name",
            placeholder: "First name"
          }, {
            id: "lastName",
            label: "Last Name",
            placeholder: "Last name",
          }, {
            id: "dateOfBirth",
            label: "Date of Birth",
            placeholder: "Date of Birth",
          }, {
            id: "gender",
            label: "Gender",
            placeholder: "Gender",
          }, {
            id: "userType",
            label: "User Type",
          }]
        }
      }
    }),
    Session.init()
  ]
});


/* Your App */
class App extends React.Component {
  async getToken(): Promise<void> {
    const accessToken = await Session.getAccessToken();
    console.log(accessToken);
  }

  render() {
    if (canHandleRoute([EmailPasswordPreBuiltUI, EmailVerificationPreBuiltUI])) {
      // This renders the login UI on the /auth route
      return getRoutingComponent([EmailPasswordPreBuiltUI, EmailVerificationPreBuiltUI])
    }

    this.getToken().then(r => console.log(r))

    return (
      <SuperTokensWrapper>{/* Your app here */}</SuperTokensWrapper>
    );
  }
}

export default App;
